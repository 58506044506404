<template>
    <router-view></router-view>

    <bs-search-form>
        <template v-slot:title>Report Realisasi Conco Delco</template>
        <template v-slot:content>
            <div class="col-lg-6 col-sm-12">
                <div class="row mb-3">
                    <label for="filterCustomer" class="col-sm-4 col-form-label">SO Doc Date</label>
                    <div class="col-sm-4">
                        <bs-date-picker v-model="filter.SODocDateFrom" />
                    </div>
                    <div class="col-sm-4">
                        <bs-date-picker v-model="filter.SODocDateUntil" />
                    </div>
                </div>
                
                <!-- <div class="row mb-3">
                    <label for="filterCustomer" class="col-sm-4 col-form-label">SO Created Date</label>
                    <div class="col-sm-4">
                        <bs-date-picker v-model="filter.SOCreatedDateFrom" />
                    </div>
                    <div class="col-sm-4">
                        <bs-date-picker v-model="filter.SOCreatedDateUntil" />
                    </div>
                </div> -->

                <div class="row mb-3">
                    <label for="filterCustomer" class="col-sm-4 col-form-label">SO Type</label>
                    <div class="col-sm-8">
                        <bs-textbox v-model="filter.SOTypeEquals" />
                    </div>
                </div>

                <div class="row mb-3">
                    <label for="filterCustomer" class="col-sm-4 col-form-label">SO Doc Number</label>
                    <div class="col-sm-8">
                        <bs-textbox v-model="filter.SONumberContains" />
                    </div>
                </div>
                
                <!-- <div class="row mb-3">
                    <label for="filterCustomer" class="col-sm-4 col-form-label">Delivery Date</label>
                    <div class="col-sm-4">
                        <bs-date-picker v-model="filter.SOCreatedDateFrom" />
                    </div>
                    <div class="col-sm-4">
                        <bs-date-picker v-model="filter.SOCreatedDateUntil" />
                    </div>
                </div> -->

                <div class="row mb-3">
                    <label for="filterCustomer" class="col-sm-4 col-form-label">Status</label>
                    <div class="col-sm-8">

                        <review-invoice-status-combo v-model="filter.StatusEquals" />
                    </div>
                </div>

            </div>

            <button class="btn btn-primary btn-sm" @click="loadData()"><i class="bi bi-search"></i> Search</button>
            <button class="btn btn-primary btn-sm ms-1" @click="downloadExcel">
                <i class="bi bi-file-earmark-excel"></i> Download Excel
            </button>
        </template>
    </bs-search-form>

    <div class="row justify-content-between mt-3">
        <div class="col-md-6">
            
        </div>
        <div class="col-md-6">
            <div class="pagination-right">
                <bs-pagination :pageNumber="pageNumber" :perPage="perPage" :totalPage="totalPage" :totalRow="totalRow" @toPage="toPage" />
            </div>
        </div>
    </div>

    <div class="table-responsive">
        <table class="table table-stripped">
            <thead>
                <tr>
                    <th>SO Number</th>
                    <th>Plant</th>
                    <th>SO Type</th>
                    <th>SO Doc Date</th>
                    <th>Ship To</th>
                    <th>Ship To Name</th>
                    <th>Location</th>
                    <th>Delivery date</th>
                    <th>Receipt No</th>
                    <th>Sold To </th>
                    <th>Sold To Name</th>
                    <th>Payer</th>
                    <th>Aircraft Registration</th>
                    <th>Flight Number</th>
                    <th>Material</th>
                    <th>Qty in Sales Unit</th>
                    <th>Sales Unit</th>
                    <th>Qty SO in Gal</th>
                    <th>Qty SO in KL</th>
                    <th>SO Amount in USD</th>
                    <th>Amount SO in IDR</th>
                    <th>Billing Number</th>
                    <th>Billing Date</th>
                    <th>Invoice List</th>
                    <th>Invoice List Date</th>
                    <th>Vendor Code</th>
                    <th>Vendor Name</th>
                    <th>PR Number</th>
                    <th>Amount PR in USD</th>
                    <th>PO Number</th>
                    <th>PO Doc Date</th>
                    <th>MIRO Number</th>
                    <th>MIRO Document Date</th>
                    <th>MIRO Item</th>
                    <th>Qty MIRO in Order Unit</th>
                    <th>MIRO Order Unit</th>
                    <th>Qty MIRO in Gal</th>
                    <th>Qty Miro in KL</th>
                    <th>Status</th>
                    <th>Rejection Status</th>
                </tr>
            </thead>
            <tbody>
                <template v-if="data != null && data.length > 0">
                    <tr v-for="item in data" :key="item.id">
                        <td>{{item.soNumber}}</td>
                        <td>{{item.plant}}</td>
                        <td>{{item.soType}}</td>
                        <td><view-date v-model="item.soDocDate" /></td>
                        <td>{{item.shipTo}}</td>
                        <td>{{item.shipToName}}</td>
                        <td>{{item.location}}</td>
                        <td><view-date v-model="item.deliveryDate" /></td>
                        <td>{{item.receiptNo}}</td>
                        <td>{{item.soldTo}}</td>
                        <td>{{item.soldToName}}</td>
                        <td>{{item.payer}}</td>
                        <td>{{item.aircraftRegistration}}</td>
                        <td>{{item.flightNumber}}</td>
                        <td>{{item.material}}</td>
                        <td><bs-plain-number v-model="item.qtyInSalesUnit" :decimal-place="3" /></td>
                        <td>{{item.salesUnit}}</td>
                        <td><bs-plain-number v-model="item.qtySoInGal" :decimal-place="3" /></td>
                        <td><bs-plain-number v-model="item.qtySoInKl" :decimal-place="3" /></td>
                        <td><bs-plain-number v-model="item.soAmountInUsd" :decimal-place="2" /></td>
                        <td><bs-plain-number v-model="item.amountSoInIdr" :decimal-place="2" /></td>
                        <td>{{item.billingNumber}}</td>
                        <td><view-date v-model="item.billingDate" /></td>
                        <td>{{item.invoiceList}}</td>
                        <td><view-date v-model="item.invoiceListDate" /></td>
                        <td>{{item.vendorCode}}</td>
                        <td>{{item.vendorName}}</td>
                        <td>{{item.prNumber}}</td>
                        <td><bs-plain-number v-model="item.amountPrInUsd" /></td>
                        <td>{{item.poNumber}}</td>
                        <td><view-date v-model="item.poDocDate" /></td>
                        <td>{{item.miroNumber}}</td>
                        <td><view-date v-model="item.miroDocDate" /></td>
                        <td>{{item.miroItem}}</td>
                        <td><bs-plain-number v-model="item.miroQty" :decimalPlace="3" /></td>
                        <td>{{item.miroOrderUnit}}</td>
                        <td><bs-plain-number v-model="item.qtyMIROInGal" :decimal-place="3" /></td>
                        <td><bs-plain-number v-model="item.qtyMIROInKL" :decimal-place="3" /></td>
                        <td>{{item.status}}</td>
                        <td>{{ item.rejectionStatus }}</td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
import ReportRealisasiConcoDelcoService from "@/services/report-realisasi-conco-delco-service";

export default {
    data: function () {
        let currenctDate = new Date();
        return {
            filter: {
              SODocDateFrom: new Date(currenctDate.getFullYear(), currenctDate.getMonth(), 1),
              SODocDateUntil: new Date(currenctDate.getFullYear(), currenctDate.getMonth() + 1, 0)
            },
            errorMessages: null,
            pageNumber: 1,
            perPage: 10,
            totalPage: 100,
            totalRow: 0,
            data: Array(),
        }
    },
    watch: {
        $route: function() {
            if (this.$route.path == "/report-realisasi-conco-delco") {
                this.loadData();
            }
        }
    },
    mounted() {
        this.loadData();
    },
    methods: {
        async toPage(pageNumber) {
            this.filter.page = pageNumber;
            this.loadData();
        },
        async loadData() {
            try {
                const result = await ReportRealisasiConcoDelcoService.getList(this.filter);
                
                const data = result.data;

                this.pageNumber = data.pageNumber;
                this.perPage = data.perPage;
                this.totalPage = data.totalPage;
                this.totalRow = data.totalRow;
                this.data = data.data;
            } 
            catch (e) {
                this.pageNumber = 0;
                this.perPage = 0;
                this.totalPage = 0;
                this.totalRow = 0;
                this.data = Array();
            }
        },
        async downloadExcel() {
          try {
            this.$store.commit('progressCounterQueueIncrement');
            await ReportRealisasiConcoDelcoService.downloadExcel(this.filter);
          }
          finally {
            this.$store.commit('progressCounterQueueDecrement');
          }
        }
    }
}
</script>

<style scoped>
    th, td {
        white-space: nowrap;
    }
</style>