import HttpService from './http-service.js';
const httpService = new HttpService();

export default ({
    async downloadExcel(filter) {
        const response = await httpService.downloadExcelWithFilter('Reports/ReportRealisasiExcel', filter);
        return response;
    },
    async getList(filter) 
    {
        const response = await httpService.get(`Reports/ReportRealisasi`, filter);

        const result = {
            pageNumber: 0,
            perPage: 0,
            totalPage: 0,
            totalRow: 0,
            data: Array()
        };

        if (!response.status) 
        {
            response.data = result;
            return response;
        }

        result.pageNumber = response.data.pageNumber;
        result.perPage = response.data.perPage;
        result.totalPage = response.data.totalPage;
        result.totalRow = response.data.totalRow;
        
        for(let i = 0; i < response.data.data.length; i++) 
        {
            result.data.push(this._toObject(response.data.data[i]));
        }

        response.data = result;
        return response;
    },
    
    default() {
        return {
            id:0,
            soNumber:null,
            plant:null,
            soType:null,
            soDocDate:null,
            soCreatedDate:null,
            shipTo:null,
            shipToName:null,
            location:null,
            deliveryDate:null,
            receiptNo:null,
            soldTo:null,
            soldToName:null,
            payer:null,
            payerName:null,
            aircraftRegistration:null,
            flightNumber:null,
            material:null,
            qtyInSalesUnit:0,
            salesUnit:null,
            qtySoInGal:0,
            qtySoInKl:0,
            soAmountInUsd:0,
            amountSoInIdr:0,
            billingNumber:null,
            billingDate:null,
            invoiceList:null,
            invoiceListDate:null,
            vendorCode:null,
            vendorName:null,
            prNumber:null,
            amountPrInUsd:0,
            poNumber:null,
            poDocDate:null,
            miroNumber:null,
            miroDocDate:null,
            miroItem:null,
            miroQty:0,
            miroOrderUnit:null,
            orderUnit:null,
            qtyMIROInGal:0,
            qtyMIROInKL:0,
            status:null,
            rejectionStatus: null
        };
    },

    _toObject(jsonData) {
        const result = this.default();
        result.id = jsonData.id;
        result.soNumber = jsonData.soNumber;
        result.plant = jsonData.plant;
        result.soType = jsonData.soType;
        result.soDocDate = jsonData.soDocDate;
        result.soCreatedDate = jsonData.soCreatedDate;
        result.shipTo = jsonData.shipTo;
        result.shipToName = jsonData.shipToName;
        result.location = jsonData.location;
        result.deliveryDate = jsonData.deliveryDate;
        result.receiptNo = jsonData.receiptNo;
        result.soldTo = jsonData.soldTo;
        result.soldToName = jsonData.soldToName;
        result.payer = jsonData.payer;
        result.payerName = jsonData.payerName;
        result.aircraftRegistration = jsonData.aircraftRegistration;
        result.flightNumber = jsonData.flightNumber;
        result.material = jsonData.material;
        result.qtyInSalesUnit = jsonData.qtyInSalesUnit;
        result.salesUnit = jsonData.salesUnit;
        result.qtySoInGal = jsonData.qtySoInGal;
        result.qtySoInKl = jsonData.qtySoInKl;
        result.soAmountInUsd = jsonData.soAmountInUsd;
        result.amountSoInIdr = jsonData.amountSoInIdr;
        result.billingNumber = jsonData.billingNumber;
        result.billingDate = jsonData.billingDate;
        result.invoiceList = jsonData.invoiceList;
        result.invoiceListDate = jsonData.invoiceListDate;
        result.vendorCode = jsonData.vendorCode;
        result.vendorName = jsonData.vendorName;
        result.prNumber = jsonData.prNumber;
        result.amountPrInUsd = jsonData.amountPrInUsd;
        result.poNumber = jsonData.poNumber;
        result.poDocDate = jsonData.poDocDate;
        result.miroNumber = jsonData.miroNumber;
        result.miroDocDate = jsonData.miroDocDate;
        result.miroItem = jsonData.miroItem;
        result.miroQty = jsonData.miroQty;
        result.miroOrderUnit = jsonData.miroOrderUnit;
        result.orderUnit = jsonData.orderUnit;
        result.qtyMIROInGal = jsonData.qtyMIROInGal;
        result.qtyMIROInKL = jsonData.qtyMIROInKL;
        result.status = jsonData.status;
        result.rejectionStatus = jsonData.rejectionStatus;
        return result;
    }
});